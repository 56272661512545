import React from 'react';
import { Link } from 'gatsby';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  // TestimonialList,
  ReviewList,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import {
  PAGES,
  TRIP_ADVISOR_URL,
  GOOGLE_REIVEW_URL,
  BASE_URL,
} from 'config';
// import { FORMAT_GRAPH_DATA } from 'utilities';

import SVGFacebook from '../../../../public/svg/social/facebook-square.svg';

const BREADCRUMBS = [
  {
    name: PAGES.ABOUT.TITLE,
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.ABOUT_REVIEWS.TITLE,
    absolute_slug: PAGES.ABOUT_REVIEWS.PATH,
  },
];

// const REVIEWS_PAGE_LENGTH = 10;

const AboutReviews = () => (
  <Layout page="about/reviews">
    <Helmet
      title="Reviews and What Our Guests Say About Us | Haka Tours, NZ"
      meta={[
        {
          name: 'description', content: `We love what we do, and it seems from our guests' reviews that they love what we do, too!
          We're delighted that we have given so many people the best New Zealand tour they coul ever imagine. `,
        },
        { name: 'og:title', content: 'Reviews and What Our Guests Say About Us | Haka Tours, NZ' },
        {
          name: 'og:description', content: `We love what we do, and it seems from our guests' reviews that they love what we do, too!
          We're delighted that we have given so many people the best New Zealand tour they coul ever imagine. `,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/reviews/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/000/reviews_banner.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Reviews</h2>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <h1 className="c-heading c-heading--h2">Latest Reviews</h1>
        <p>We’re proud of the awards we’ve won as New Zealand’s leading small group adventure tour operator, and we focus all our energy on delivering exceptional travel experiences.</p>
        <p>But don’t just take our word for it, check out what our customers have to say!</p>
      </div>
    </section>
    <section className="l-reviews">
      <div className="l-container">
        <div className="l-reviews__actions">
          <Link
            to={PAGES.CONTACT.PATH}
            className="c-button c-button--border"
          >
            Message us your review
          </Link>
          <a
            href="https://www.facebook.com/pg/HakaTours/reviews/?ref=page_internal"
            target="_blank"
            rel="noopener noreferrer"
            className="c-button c-button--facebook-border"
          >
            <SVGFacebook /> Review us on Facebook
          </a>
          <a
            href={GOOGLE_REIVEW_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="c-button c-button--google-border"
          >
            <img
              src="https://hakatours-staging.imgix.net/000/google-logo.png"
              alt="Google"
              className="google-icon"
            /> Review us on Google
          </a>
          <a
            href={TRIP_ADVISOR_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="c-button c-button--tripadvisor-border"
          >
            <img
              src="https://hakatours-staging.imgix.net/000/tripadvisor-logo.png"
              alt="Tripadvisor"
              className="tripadvisor-icon"
            /> Review us on TripAdvisor
          </a>
        </div>
        <div>
          <ReviewList />
        </div>
      </div>
    </section>
  </Layout>
);

// export const query = graphql`
//   query {
//     allReviews(
//       sort: {
//         fields: date_published,
//         order: DESC
//       }
//       filter: {
//         date_published: {
//           ne: null
//         }
//       }
//     ) {
//       edges {
//         node {
//           id
//           api_id
//           author
//           country
//           text
//           image_url
//           date_published
//         }
//       }
//     }
//   }
// `;

// AboutReviews.propTypes = {
//   data: PropTypes.any.isRequired,
// };

export default AboutReviews;
